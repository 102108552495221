<template>
  <div id="app">
    <h1>Kassperr AI</h1>
    <div id="input-container">
      <input v-model="inputText" type="text"/>
      <button  class="search-button" @click="generateText">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div id="result-container">
      <p class="result-title">Résultat:</p>
      <p>{{ outputText }}</p>
    </div>
    <button class="speak-button" @click="speak">
      Speak
    </button>
  </div>
</template>

<script>

export default {
  data() {
    return {
      inputText: "",
      outputText: ""
    }
  },
  methods: {
    async generateText() {

      const { Configuration, OpenAIApi } = require("openai");

      const configuration = new Configuration({
      apiKey: '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',
      headers: {
        'User-Agent': 'MonAgentUtilisateur'
      },
      timeout: 5000
    });
    const openai = new OpenAIApi(configuration);

    const completion = await openai.createCompletion({
      model: "text-davinci-002",
      prompt: this.inputText,
      max_tokens: 1000
    });
    this.outputText =  completion.data.choices[0].text;
        },
        speak() {
      const speech = new SpeechSynthesisUtterance(this.outputText);
      window.speechSynthesis.speak(speech);
    }
      }
}
</script>

<style>
@import "~@fortawesome/fontawesome-free/css/all.css";
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  margin: 0;
  padding: 0;
  background-color: #1c2331;
   height: 100%;
   
}
#app > * {
  margin-top: 0;
}

#input-container {
  display: flex;
  align-items: center;
  margin: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

  h1 {
    padding: 30px;
    color: white;
  }

  input {
    flex: 1;
    height: 50px;
    font-size: 20px;
    padding-left: 10px;
    background-color: #34495e;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 5px;
  }

.search-button {
  width: 50px;
  height: 50px;
  border-radius: 20%;
  border: none;
  background-color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-button:hover {
  background-color: #818181;
}

.search-button i {
  color: #555;
  font-size: 18px;
}

.speak-button{
  width: 100px;
  height: 50px;
  border-radius: 8%;
  border: none;
  background-color: #e6e6e6;
  cursor: pointer;
}

.result-title{
  justify-content: flex-start;
  align-items: flex-start;
}

  #result-container {
    min-width: 80%;
    background-color: #34495e;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
  }
</style>
